/** @format */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  box-sizing: border-box;
}
@font-face {
  font-family: "eurostileunicaseltpro";
  src: url(./font/eurostileunicaseltpro-maisfontes.2928/eurostileunicaseltpro.otf);
}
p {
  font-family: "Inter", sans-serif !important;
  margin-bottom: 0px !important;
}
.font-para {
  font-family: "Inter", sans-serif !important;
}
.font-head {
  font-family: "eurostileunicaseltpro";
}
::selection {
  background: #fff;
  color: #000;
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #000;
}
a {
  text-decoration: none !important;
}
body {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  overflow-x: hidden !important;
  position: relative !important;
  background: linear-gradient(
    89deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(87, 84, 108, 0.47) 20%,
    rgba(87, 84, 108, 0.54) 81%,
    rgba(255, 255, 255, 1) 100%
  );
}
.navbar-toggler {
  outline: none !important;
  box-shadow: none !important;
}
.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none !important;
  position: relative;
  border-bottom: 2px solid #000;
  transition: all 300ms linear;
}

.navbar .navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #000;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar .navbar-toggler-icon:after {
  top: 8px;
}
p {
  margin-bottom: 0px !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}

.navbar-toggler {
  border: none !important;
}
.collapse {
  visibility: visible !important;
}

.i::before {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  opacity: 20%;
  background: white;
  /*   background: #3B82F6; */
  /* Centering */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.i:hover:before {
  animation: anim-in 0.7s forwards ease-out;
}
.faqs {
  background: linear-gradient(95.64deg, #57546c4d -117.13%, #f1f1f877 89.73%);
}
.card_main {
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: auto 100%;
  margin: 0px 0px 107px;
  // padding: 32px;
  background: linear-gradient(95.64deg, #57546c4d -117.13%, #f1f1f877 89.73%),
    url("../src/media/images/border.svg") no-repeat !important;
  border-radius: 24px;
}
@keyframes anim-in {
  100% {
    opacity: 0%;
    border-radius: 0;
    width: 600px;
    height: 600px;
  }
  0% {
    width: 0px;
    height: 0px;
    border-radius: 100%;
    opacity: 20%;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.borde {
  border: 1px solid rgb(20, 20, 20);
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.selected {
  background-color: transparent !important;
  transition: all 2s ease-in-out !important;
}
.btn_buy {
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  font-family: "eurostileunicaseltpro" !important;
  color: #000;
  transition: all 2s ease-in-out !important;

  padding: 14px;
  width: 100%;
  text-align: center;
}
.btn_sell {
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  transition: all 2s ease-in-out !important;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  font-family: "eurostileunicaseltpro" !important;
  color: #000;
  padding: 14px;
  width: 100%;
  text-align: center;
}

.tile {
  padding: 40px 40px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  background: linear-gradient(
        95.64deg,
        rgba(218, 218, 218, 0.15) -117.13%,
        rgba(218, 218, 218, 0.15) 89.73%
      )
      no-repeat,
    url("media/images/IntegrationsCardBorder.0f7c4a81.svg") !important;
  background-repeat: no-repeat !important;
  border-radius: 15px 24px 24px;
}
.caarss {
  background-color: rgba(218, 218, 218, 0.15) !important;
  border-radius: 24px;
}
.tile h3,
.tile h4 {
  margin: 0;
}

.proposals i {
  font-size: 10px;
  position: relative;
  top: -2px;
  opacity: 0.5;
}

.proposals .badge {
  background: none;
  border-radius: 5px;
  padding: 4px 10px 5px 10px;
  font-weight: bold;
  top: -2px;
  position: relative;
}

.proposals .badge-warning {
  border: 1px solid #f9a825;
  color: #f9a825;
}

.proposals .badge-success {
  border: 1px solid #4caf50;
  color: #4caf50;
}

.proposalsLink:hover {
  background: #f5f5f5;
}

.proposalsLink .link {
  cursor: pointer;
}

.fs-24 {
  font-size: 24px;
}

.fs-20 {
  font-size: 20px;
}

.spinner {
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: -75px;
}

.spinner span {
  position: absolute;
  top: 50%;
  left: var(--left);
  width: 35px;
  height: 7px;
  background: #000000;
  animation: dominos 1s ease infinite;
  box-shadow: 2px 2px 3px 0px rgb(180, 178, 178);
}

.spinner span:nth-child(1) {
  --left: 80px;
  animation-delay: 0.125s;
}

.spinner span:nth-child(2) {
  --left: 70px;
  animation-delay: 0.3s;
}

.spinner span:nth-child(3) {
  left: 60px;
  animation-delay: 0.425s;
}

.spinner span:nth-child(4) {
  animation-delay: 0.54s;
  left: 50px;
}

.spinner span:nth-child(5) {
  animation-delay: 0.665s;
  left: 40px;
}

.spinner span:nth-child(6) {
  animation-delay: 0.79s;
  left: 30px;
}

.spinner span:nth-child(7) {
  animation-delay: 0.915s;
  left: 20px;
}

.spinner span:nth-child(8) {
  left: 10px;
}

@keyframes dominos {
  50% {
    opacity: 0.7;
  }

  75% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  80% {
    opacity: 1;
  }
}

.formSer button {
  border: none;
  background: none;
  color: #8b8ba7;
}
/* styling of whole input container */
.formSer {
  --timing: 0.3s;
  --width-of-input: 200px;
  --height-of-input: 40px;
  --border-height: 2px;

  --border-color: #000000;
  --border-radius: 30px;
  --after-border-radius: 1px;
  position: relative;
  display: flex;
  align-items: center;
  padding-inline: 0.8em;
  border-radius: var(--border-radius);
  transition: border-radius 0.5s ease;
  background: rgba(189, 188, 188, 0.555);
  color: #000;
  max-width: 50% !important;
}
/* styling of Input */
.formSer .input {
  font-size: 0.9rem;
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding-inline: 0.5em;
  padding-block: 0.7em;
  border: none;
  color: #000;
}
/* styling of animated border */
.formSer:before {
  content: "";
  position: absolute;
  background: var(--border-color);
  transform: scaleX(0);
  transform-origin: center;
  width: 100%;
  height: var(--border-height);
  left: 0;
  bottom: 0;
  border-radius: 1px;
  transition: transform var(--timing) ease;
}
/* Hover on Input */
.formSer:focus-within {
  border-radius: var(--after-border-radius);
}

input:focus {
  outline: none;
}
/* here is code of animated border */
.formSer:focus-within:before {
  transform: scale(1);
}
/* styling of close button */
/* == you can click the close button to remove text == */
.reset {
  border: none;
  background: none;
  opacity: 0;
  visibility: hidden;
}
/* close button shown when typing */
input:not(:placeholder-shown) ~ .reset {
  opacity: 1;
  visibility: visible;
}
/* sizing svg icons */
.formSer svg {
  width: 17px;
  margin-top: 3px;
}
.Referred {
  -webkit-box-pack: center;
  align-items: center;
  background-color: #0000003d;
  border: 1px solid #ffffff29;
  border-radius: 40px;
  color: #000000 !important;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 10px;
  pointer-events: auto;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  width: 30%;
}
